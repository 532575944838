import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { baseUrl } from "../utils/constants";
import moment from "moment";
import localizationStrings from "../utils/localizations";

// quran_tafseer.json
// quran_pavitra.json
// surah_intro.json
// parah.json

export default function Download() {
  const downloadFile = async (file) => {
    const res = await axios.post(baseUrl + "data/" + file, {});
    const data = res.data;

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data, null, 2));
    var dlAnchorElem = document.getElementById("downloadAnchorElem");
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", file);
    dlAnchorElem.click();
  };

  return (
    <Box
      style={{
        backgroundColor: "#fff",
        paddingTop: 15,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <a id="downloadAnchorElem" style={{ display: "none" }} href="#blank">
        a
      </a>
      {["quran_tafseer.json", "quran_pavitra.json", "surah_intro.json", "parah.json"].map((file) => (
        // <Button variant="contained" color="primary" onClick={() => window.open(baseUrl + "data/" + file)} style={{ marginTop: 20 }}>
        <Button variant="contained" color="primary" onClick={() => downloadFile(file)} style={{ marginTop: 20 }}>
          {file}
        </Button>
      ))}
    </Box>
  );
}
